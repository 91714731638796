<template name="component-name">
  <div>
    <v-layout wrap class="px-1">
      <v-flex xs12>
        <v-layout>
          <v-flex xs6>
            <v-flex xs2>
              <h3
                class="global-textTwoStyle text-center"
                style="border-bottom: 3px solid"
              >
                General
              </h3>
            </v-flex>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
        <v-layout class="mb-5">
          <v-flex xs6>
            <v-card elevation="0" class="global-cardStyle22">
              <v-layout justify-center align-center>
                <v-flex class="justify-center text-center mt-15 mb-15" xs6>
                  <image-input v-model="avatar">
                    <div slot="activator">
                      <v-avatar
                        size="200px"
                        v-ripple
                        v-if="!avatar"
                        class="grey lighten-3 mb-3"
                      >
                        <img :src="profile_data.foto_perfil" alt="avatar" />
                      </v-avatar>
                      <v-avatar size="200px" v-ripple v-else class="mb-3">
                        <img :src="avatar.imageURL" alt="avatar" />
                      </v-avatar>
                    </div> </image-input
                ></v-flex>
                <v-flex xs6>
                  <v-layout wrap justify-center class="mt-10 mr-3 ml-3">
                    <v-flex xs10>
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.usuario"
                        placeholder="RUT"
                        :hide="false"
                        label="RUT"
                        readonly="true"
                        :rules="[rules.rut, rules.required]"
                        max_length="12"
                        :rut="true"
                      />
                    </v-flex>

                    <v-flex xs10 class="mt-2">
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.nombre"
                        placeholder="Nombre"
                        :hide="false"
                        label="Nombre"
                        @input="profile_data.nombre = $event"
                        :rules="[rules.required]"
                        readonly="false"
                      />
                    </v-flex>

                    <v-flex xs10 class="mt-2">
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.primer_apellido"
                        placeholder="Primer apellido"
                        :hide="false"
                        label="Primer apellido"
                        @input="profile_data.primer_apellido = $event"
                        :rules="[rules.required]"
                        type="text"
                        readonly="false"
                      />
                    </v-flex>
                    <v-flex xs10 class="mt-2">
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.segundo_apellido"
                        placeholder="Segundo apellido"
                        :hide="false"
                        label="Segundo apellido"
                        @input="profile_data.segundo_apellido = $event"
                        readonly="false"
                      />
                    </v-flex>
                    <v-flex xs10 class="mt-2">
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.celular"
                        placeholder="Teléfono"
                        :hide="false"
                        label="Teléfono"
                        @input="profile_data.celular = $event"
                        :rules="[rules.required, rules.telefono]"
                        readonly="false"
                      />
                    </v-flex>
                    <v-flex xs10 class="mt-2">
                      <Input
                        size="long"
                        color="#0077c8"
                        detail_color="white"
                        :model="profile_data.correo_electronico"
                        readonly="false"
                        placeholder="Correo"
                        :hide="false"
                        label="Correo"
                        @input="profile_data.correo_electronico = $event"
                        :rules="[rules.required, rules.email]"
                      />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout wrap class="mr-3 ml-3" justify-center>
                <v-flex xs11>
                  <h3 class="global-counterStyle">Cambiar contraseña</h3>
                </v-flex>
                <v-flex xs11 class="mt-4">
                  <Input
                    size="long"
                    color="#0077c8"
                    detail_color="white"
                    :model="profile_data.clave"
                    placeholder="Contraseña actual"
                    readonly="false"
                    :hide="false"
                    label="Contraseña actual"
                    :type="type"
                    @input="profile_data.clave = $event"
                    :rules="[rules.required]"
                  />
                </v-flex>

                <v-flex xs11 class="mt-2">
                  <Input
                    size="long"
                    color="#0077c8"
                    detail_color="white"
                    :model="NewPassword.text"
                    readonly="false"
                    placeholder="Contraseña nueva"
                    :hide="false"
                    label="Contraseña nueva"
                    :type="type"
                    @input="NewPassword.text = $event"
                    max_length="14"
                    :rules="NewPassword.rules"
                  />
                </v-flex>

                <v-flex xs11 class="mt-2">
                  <Input
                    size="long"
                    color="#0077c8"
                    detail_color="white"
                    :model="ConfirmPassword.text"
                    placeholder="Confirmar contraseña"
                    :hide="false"
                    label="Confirmar contraseña"
                    :type="type"
                    @input="ConfirmPassword.text = $event"
                    max_length="14"
                    :rules="ConfirmPassword.rules"
                    readonly="false"
                  />
                </v-flex>
                <v-flex xs11>
                  <v-layout justify-start>
                    <btnicon
                      v-on:click.native="Show"
                      class="btn"
                      :icon="
                        type === 'password' ? 'bi bi-eye-slash' : 'bi bi-eye'
                      "
                      :text="'Ver contraseña'"
                    />
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout justify-center class="mr-3">
                <v-flex xs11 class="text-end">
                  <btnprincipal
                    class="ml-4"
                    color="#0077c8"
                    label="Guardar"
                    width="155"
                    height="45"
                    :disabled="!formValid"
                    v-on:click.native="SaveProfile"
                    :loading="this.$store.getters.updateStatus"
                  />
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
// import Button from "@/components/Button/Icon-btn";
import ImageInput from "./ImageInput.vue";
import btnicon from "@/components/Button/Btn-icon.vue";
import Input from "@/components/Inputs/Input.vue";
import btnprincipal from "@/components/Button/Btn-Principal.vue";
export default {
  components: {
    // Button,
    btnicon,
    Input,
    btnprincipal,
    ImageInput,
  },
  watch: {
    avatar: {
      handler: function () {
        this.saved = false;
        this.toBase64(this.avatar.imageFile);
      },
      deep: true,
    },
  },
  data() {
    return {
      page: "02",
      avatar: null,
      saving: false,
      saved: false,
      type: "password",
      newpassword: "",
      confirmpassword: "",
      profile_data: {},
      profile_image: null,
      NewPassword: {
        text: "",
        rules: [
          (value) => !!value || "Contraseña requerida",

          (value) => {
            const pattern = /^(?=.{8,20}$)(?=.*[0-9])(?=.*[a-zA-Z]).*/;
            return (
              (pattern.test(value) && value.length > 7 && value.length < 15) ||
              "Contraseña debe ser alfanumerica y puede contener entre 8 a 15 caracteres."
            );
          },
        ],
      },
      ConfirmPassword: {
        text: "",
        rules: [
          (value) => !!value || "Contraseña requerida",

          (value) => {
            return (
              value === this.NewPassword.text || "Las contraseñas no coinciden"
            );
          },
        ],
      },
      rules: {
        required: (value) => !!value || "Campo requerido.",
        rut: (value) => {
          const pattern = /^\d{1,2}\d{3}\d{3}[0-9kK]{1}$/;
          const patternTwo = /^\d{1,2}\.\d{3}\.\d{3}[-][0-9kK]{1}$/;
          return (
            pattern.test(value) || patternTwo.test(value) || "Rut inválido"
          );
        },
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo inválido.";
        },
        telefono: (value) => {
          const pattern = /^\d+$/;
          return pattern.test(value) || "Numero inválido";
        },
        password: (value) => {
          const pattern = /^(?=.{8,20}$)(?=.*[0-9])(?=.*[a-zA-Z]).*/;
          return (
            (pattern.test(value) && value.length > 7 && value.length < 15) ||
            "Contraseña debe ser alfanumerica y puede contener entre 8 a 15 caracteres."
          );
        },
        pass_required: (value) => !!value || "Contraseña requerida",
      },
    };
  },
  methods: {
    toBase64(file) {
      new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          var fileData = reader.result.toString().split(",");
          this.profile_image = fileData[1];

          this.$emit("this.file", reader.result);
          resolve(this.profile_image);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    uploadImage() {
      this.saving = true;
      setTimeout(() => this.savedAvatar(), 1000);
    },
    savedAvatar() {
      this.saving = false;
      this.saved = true;
    },
    goBack() {
      this.$router.go(-1);
    },

    Show() {
      if (this.type === "password") this.type = "text";
      else this.type = "password";
    },

    SaveProfile() {
      var dataProfile = {
        nombre: this.profile_data.nombre,
        primer_apellido: this.profile_data.primer_apellido,
        segundo_apellido: this.profile_data.segundo_apellido,
        celular: this.profile_data.celular,
        correo_electronico: this.profile_data.correo_electronico,
        usuario: this.profile_data.usuario,
        clave_actual: this.profile_data.clave,
        clave_nueva: this.NewPassword.text,
        foto_perfil: this.profile_image,
        extension: "png",
      };

      this.$store.dispatch("UpdateProfile", dataProfile);

      if (this.$store.getters.updateStatus === true) {
        this.$store.dispatch("GetProfileData", {
          rut: this.profile_data.usuario,
        });
        this.profile_data.clave = this.NewPassword.text;
        this.NewPassword.text = "";
        this.ConfirmPassword.text = "";
      }
      this.$store.dispatch("GetProfileData", {
        rut: this.profile_data.usuario,
      });
    },
  },
  created() {
    this.$store.commit("setPage", { page: this.page });
    if (this.user) {
      this.profile_data.nombre = this.user.nombre;
      this.profile_data.primer_apellido = this.user.primer_apellido;
      this.profile_data.celular = this.user.celular;
      this.profile_data.segundo_apellido = this.user.segundo_apellido;
      this.profile_data.correo_electronico = this.user.correo_electronico;
      this.profile_data.usuario = this.user.usuario;
      this.profile_data.foto_perfil = this.user.foto_usuario;
    }
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
    formValid() {
      return (
        this.profile_data.nombre &&
        this.profile_data.primer_apellido &&
        this.profile_data.celular &&
        this.profile_data.correo_electronico
      );
    },
  },
};
</script>
<style scoped>
@import "../../assets/CSS/ServiceStyle.css";
</style>

<template>
  <div>
    <v-btn text class="btn global-btn ma-1" :color="btnColor">
      <v-icon small class="mr-2">
        {{ icon }}
      </v-icon>
      {{ text }}
    </v-btn>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["text", "icon"],
  data() {
    return {
      Buttons: [
        { text: "Compartir", icon: "mdi-share-variant-outline" },
        { text: "Agregar a carrito", icon: "mdi-cart-outline" },
        { text: "Agregar persona", icon: "mdi-plus-circle-outline" },
        { text: "Limpiar", icon: "mdi-trash-can-outline" },
        { text: "Adjuntar", icon: "mdi-download-outline" },
        { text: "Eliminar", icon: "mdi-trash-can-outline" },
        { text: "Descargar", icon: "mdi-cloud-download-outline" },
        { text: "Imprimir", icon: "mdi-printer" },
        { text: "Duplicar", icon: "mdi-checkbox-multiple-blank-outline" },
        { text: "Editar", icon: "mdi-pencil-outline" },
        { text: "Filtrar", icon: "mdi-filter-outline" },
        { text: "Adjuntar", icon: "mdi-paperclip" },
        { text: "Ver Mas", icon: "mdi-plus-circle-outline" },
      ],
    };
  },
  computed: {
    ...mapGetters("Users", ["getBotonColor", "getTituloColor"]),
    btnColor() {
      return this.getBotonColor;
    },
  },
};
</script>

<style scoped src="../../assets/CSS/ServiceStyle.css">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap");
.btn {
  font-style: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}
:deep .v-btn:not(.v-btn--round).v-size--default {
  height: 36px;
  min-width: 64px;
  padding: 0 8px;
}
:deep .v-btn__content {
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1 0 auto;
  justify-content: inherit;
  line-height: normal;
  position: relative;
  transition: inherit;
  transition-property: opacity;
  text-transform: capitalize;
}
</style>
